import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';
import { has, isEmpty } from 'werkstatt';
import Layout from '../components/Layout';
import Articles from '../components/Articles';
import Header from '../components/Header';
import Content from '../components/Content';
import Seo from '../components/SEO';
import { toLower, join } from '../utils';

function Home({ data }) {
  const [filteredData, setFilteredData] = useState([]);
  const [query, setQuery] = useState('');

  const allPosts = data.all.edges;
  const hasSearchResults = filteredData && !isEmpty(query);
  const posts = hasSearchResults ? filteredData : allPosts;

  const filterPosts = (post, text) => {
    const { description, title, tags } = post.node.frontmatter;
    const lowerCaseText = toLower(text);
    const matchDescription = has(toLower(description), lowerCaseText);
    const matchTitle = has(toLower(title), lowerCaseText);
    const matchTags = tags && has(toLower(join(tags)), lowerCaseText);

    return matchDescription || matchTitle || matchTags;
  };

  const handleInputChange = (event) => {
    const text = event.target.value;
    const postsOrEmpty = data.all.edges || [];
    const filteredPosts = postsOrEmpty.filter((post) => filterPosts(post, text));
    setFilteredData(filteredPosts);
    setQuery(text);
  };

  return (
    <Layout>
      <Seo />
      <Header isHome title="Cicerone" subtitle="El blog políglota" onChange={handleInputChange} />
      <main>
        <Content isHome>
          <Articles articles={posts} />
        </Content>
      </main>
    </Layout>
  );
}

export const readAllArticlesQuery = graphql`query readAllArticles {
    all: allMarkdownRemark(
        sort: {fields: frontmatter___date, order: DESC}
        filter: {frontmatter: {isPublished: {eq: true}}}
    ) {
        edges {
            node {
                html
                frontmatter {
                    path
                    authors
                    date(formatString: "DD [de] MMMM, YYYY", locale: "es")
                    tags
                    title
                    description
                    homeImage {
                        childImageSharp {
                            gatsbyImageData(width: 275, quality: 80, layout: CONSTRAINED)
                        }
                    }
                }
                excerpt
            }
        }
    }
}
`;

Home.propTypes = {
  data: PropTypes.any.isRequired,
};

export default Home;
